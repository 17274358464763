<template>
  <div class="copy-button">
    <button
      class="button p-1 flat"
      @click="copyContent"
      :title="label || value"
    >
      {{ label || value }}
    </button>
    <input v-show="false" ref="content" :value="value" />
  </div>
</template>

<script>
import { copyToClipboard } from "@/utils/string";

export default {
  components: {},
  mixins: [],
  props: {
    label: {
      type: String
    },
    value: {
      required: true
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    copyContent() {
      try {
        copyToClipboard(this.$refs.content);
        this.$notify({
          group: "alert",
          text: "Copied to clipboard."
        });
      } catch (error) {
        //
      }
    }
  }
};
</script>

<style lang="scss">
.copy-button {
  @mixin button-style {
    border-radius: 4px;
    color: #666;
    &:hover {
      background: darken(#fff, 10);
    }
  }
  .button {
    @include button-style;
    overflow: hidden;
    max-width: inherit;
    width: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
